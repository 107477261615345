@import './helpers.scss';
@import './vendor/fm.revealator.jquery.scss';
@import './vendor/jquery.growl.scss';

.section-inner{
    max-width: 1551px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
    @media (max-width: 1600px) {
        max-width: 1300px;
    }
    @media (max-width: 1360px) {
        max-width: 1106px;
    }
    @media (max-width: 1280px) {
        max-width: 1106px;
    }
    @media (max-width: 960px) {
        max-width: 780px;
    }
    @media (max-width: 768px) {
        max-width: 611px;
    }
    @media (max-width: 501px) {
        max-width: 345px;
    }
}
a:hover {
    text-decoration: none;
}
body, html{
    overflow-x: hidden;
    scroll-behavior: smooth;
}
.error{
    color: #ff5757;
}
#growls-default{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 50%;
}

.section-head{
    background: url('../img/section-head-background.png') no-repeat 50% 50%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        position: absolute;
        top: 0;
        left: 0;
    }
    .section-inner{
        display: flex;
        flex-direction: column;
        min-height: 110vh;
        padding-top: 125px;
    }
    .navbar{
        padding-top: 10px;
        color: #fff;
        flex-shrink: 1;
        max-width: 1551px;
        margin: 0 auto;
        &-toggler{
            outline: none;
            border: none;
        }
        @media (max-width: 1600px) {
            max-width: 1300px;
        }
        @media (max-width: 1360px) {
            max-width: 1106px;
        }
        @media (max-width: 960px) {
            max-width: 100%;
            padding-left: 25px;
            padding-right: 25px;
            background: #2574CF;
        }
        &__row{
            flex: 1;
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        &__subrow{
            @media (max-width: 501px) {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
        &-outer{
            width: 100%;
            position: fixed;
            top: 0;
            z-index: 1000;
            @media (max-width: 960px) {
                background: #2574CF;
            }
            &--fixed{
                background: #2574CF;
                box-shadow: 16px 16px 35px rgba(40, 56, 69, 0.1);
                .nav-link{
                    font-size: 20px!important;
                }
                .navbar-brand{
                    width: 130px!important;
                }
            }
        }

        &-brand{
            padding-top: 0;
            @media (max-width: 1600px) {
                width: 192px;
            }
            @media (max-width: 1360px) {
                max-width: 163px;
            }
            @media (max-width: 501px) {
                max-width: 100px;
            }
            img{
                width: 100%;
            }
        }
        .nav-item{
            margin-right: 50px;
            color: #fff;
            @media (max-width: 1600px) {
                border-bottom: 4.25px solid rgba(0,0,0,0);
            }
            @media (max-width: 960px) {
                border-bottom: 2px solid rgba(0,0,0,0);
            }

            &:last-child{
                border-bottom: 6px solid rgba(0,0,0,0);
                @media (max-width: 960px) {
                    display: none;
                }
            }
            &--contacts{
                font-size: 16px;
                margin-right: 0;
                position: relative;
                display: flex;
                flex-direction: row;
                align-self: center;
                @media (max-width: 1600px) {
                    font-size: 13px;
                    font-weight: normal!important;
                }
                @media (max-width: 1360px) {
                    font-size: 11px;
                    font-weight: normal!important;
                }
            }
        }
        .nav-link{
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 1.33333px;
            color: #fff;
            border-bottom: 6px solid rgba(0,0,0,0);
            &:hover{
                border-bottom: 6px solid #FFB600;
                color: #fff;
                @media (max-width: 1600px) {
                    border-bottom: 4.25px solid #FFB600;
                }
                @media (max-width: 960px) {
                    border-bottom: 2px solid #FFB600;
                }
            }
            @media (max-width: 1600px) {
                font-size: 20px;
                font-weight: normal;
            }
            @media (max-width: 1360px) {
                font-size: 16px;
            }
        }
    }
    .nav-contacts{
        color: #E6E6E6;
        margin-bottom: 0;

        &--small{
            display: none!important;
            @media (max-width: 960px) {
                display: flex!important;
            }

        }
        &__phone{
            color: #FAFAFA;
            font-weight: 500;
            margin-right: 20px;
            &:hover{
                color: #e0e0e0;
            }
            img{
                margin-right: 10px;
            }
            @media (max-width: 960px) {
                font-size: 16px;
            }
            @media (max-width: 501px) {
                display: none!important;
            }
        }
        &__email{
            color: #FAFAFA;
            font-weight: 500;
            &:hover{
                color: #e0e0e0;
            }
            img{
                margin-right: 10px;
            }
            @media (max-width: 960px) {
                font-size: 16px;
            }
            @media (max-width: 501px) {
                display: none!important;
            }
        }
        * {
            @media (max-width: 1600px) {
                font-weight: normal;
            }
        }
    }
    &__blocks-inner{
        display: flex;
    }
    &__blocks{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        flex-grow: 1;
        align-items: flex-end;
        margin-bottom: 90px;
    }
    &__block{
        text-align: center;
        color: #fff;
        &--left{
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(5px);
            display: flex;
            padding: 27px;
            flex: 8;
            @media (max-width: 1360px) {
                padding: 27px;
            }
            @media (max-width: 960px) {
                padding: 12px 12px 6px;
            }
            @media (max-width: 768px) {
                padding: 16px 100px;
            }
            @media (max-width: 501px) {
                padding: 6px 12px;
            }
        }
        &--right{
            flex: 8;
            img{
                width: 100%;
                height: 100%;
            }
            @media (max-width: 768px) {
                display: none;
            }
        }
        &--offset{
            flex: 1;
            @media (max-width: 768px) {
                display: none;
            }
        }

        &-desc{
            @include font-roboto;
            text-align: left;
            flex: 5;
            &-logo{
                margin-right: 12px;
                img{
                    @media (max-width: 1360px) {
                        max-width: 56px;
                    }
                    @media (max-width: 1360px) {
                        max-width: 40px;
                    }
                }
            }
            h1{
                font-family: inherit;
                font-size: 60px;
                font-weight: bold;
                letter-spacing: 1.37px;
                @media (max-width: 1600px) {
                    font-size: 50px;
                }
                @media (max-width: 1360px) {
                    font-size: 42px;
                }
                @media (max-width: 960px) {
                    font-size: 29px;
                }
                @media (max-width: 768px) {
                    font-size: 26px;
                }
                @media (max-width: 501px) {
                    margin-bottom: 0;
                }
            }
            h2{
                font-family: inherit;
                font-style: normal;
                font-weight: 500;
                font-size: 37px;
                letter-spacing: -1.5px;
                margin-bottom: 0;
                margin-left: 95px;
                @media (max-width: 1600px) {
                    font-size: 30px;
                }
                @media (max-width: 1360px) {
                    font-size: 26px;
                    margin-left: 55px;
                }
                @media (max-width: 960px) {
                    font-size: 18.6px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                @media (max-width: 501px) {
                    margin: 0 auto;
                    margin-left: 10px;
                    position: relative;
                    top: -10px;
                }
            }
            &-lms{
                font-family: inherit;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 42px;
                letter-spacing: 1px;
                margin-top: 35px;
                color: rgb(255,182,0);
                margin-left: 95px;
                @media (max-width: 1600px) {
                    font-size: 27px;
                    margin-top: 30px;
                }
                @media (max-width: 1360px) {
                    font-size: 22px;
                    margin-top: 26px;
                    margin-bottom: 0;
                    margin-left: 55px;
                }
                @media (max-width: 960px) {
                    font-size: 16px;
                    margin-top: 10px;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
                @media (max-width: 501px) {
                    margin: 0 auto;
                }
            }
            &-for{
                font-family: inherit;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                letter-spacing: 1px;
                margin-bottom: 0;
                margin-left: 95px;
                @media (max-width: 1600px) {
                    font-size: 20px;
                }
                @media (max-width: 1360px) {
                    font-size: 17px;
                    margin-left: 55px;
                }
                @media (max-width: 960px) {
                    font-size: 12px;
                }
                @media (max-width: 768px) {
                    font-size: 13px;
                }
                @media (max-width: 501px) {
                    margin: 0 auto;
                }
            }
            @media (max-width: 768px) {
                text-align: center;
            }
        }
    }
    &__button{
        @include font-roboto;
        border: 4px solid #FFB600;
        border-radius: 66.6667px;
        padding: 12.5px 30px;
        background: none;
        margin-top: 25px;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 21.3333px;
        line-height: 25px;
        transition: 0.1ms;
        outline: none;
        margin-left: 95px;
        display: inline-block;
        &:hover{
            color: #000;
            transition: 0.1ms;
            background-color: #FFB600;
            border-color: #FFB600;
        }
        &:focus{
            outline: none;
        }
        &:active{
            outline: none;
            color: #000;
            transition: 0.1ms;
            background-color: #FF9800;
            border-color: #FF9800;
        }
        @media (max-width: 1600px) {
            font-size: 18px;
            padding: 8px 18px
        }
        @media (max-width: 1360px) {
            font-size: 15px;
            margin-top: 40px;
            border: 3px solid #FFB600;
            margin-left: 55px;
        }
        @media (max-width: 960px) {
            font-size: 10.7px;
            margin-top: 30px;
            border: 2px solid #FFB600;
            padding: 4px 15px
        }
        @media (max-width: 768px) {
            font-size: 8.5px;
        }
        @media (max-width: 501px) {
            margin: 20px auto 5px;
            font-size: 10px;
            line-height: 12px;
            padding: 5px 10px;
        }
    }
    &__achieves{
        position: relative;
        z-index: 5;
        top: 35px;
        display: flex;
        justify-content: space-around;
        margin-left: 75px;
        width: 100%;
        height: 65px;
        @media (max-width: 768px) {
            margin-left: 0;
        }
    }
    &__achiev{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100px;
        &-icon{
            margin-bottom: 15px;
            @media (max-width: 1600px) {
                max-width: 51px;
            }
            @media (max-width: 1360px) {
                max-width: 50px;
            }
            @media (max-width: 960px) {
                max-width: 30px;
            }
            @media (max-width: 501px) {
                margin-bottom: 5px;
            }
        }
        &:nth-child(1){
            color: #FFB600;
        }
        &:nth-child(2){
            color: #503D96;
        }
        &:nth-child(3){
            color: #5995F5;
        }
        &-value{
            @include font-roboto;
            font-style: normal;
            font-weight: 900;
            font-size: 49.7518px;
            line-height: 118.8%;
            text-align: center;
            margin-bottom: 5px;
            @media (max-width: 1600px) {
                font-size: 41px;
            }
            @media (max-width: 1360px) {
                font-size: 35px;
            }
            @media (max-width: 960px) {
                font-size: 24px;
            }
            @media (max-width: 768px) {
                font-size: 29px;
            }
        }
        &-title{
            @include font-montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 19.5195px;
            line-height: 118.8%;
            text-align: center;
            color: #30233D;
            margin-bottom: 0;
            @media (max-width: 1600px) {
                font-size: 16px;
            }
            @media (max-width: 1360px) {
                font-size: 13px;
            }
            @media (max-width: 960px) {
                font-size: 9px;
            }
            @media (max-width: 768px) {
                font-size: 11px;
            }
        }
    }    
    &__rewards{
        display: flex;
        flex-shrink: 1;
        margin-bottom: 40px;
        justify-content: space-between;


        &-prizes{
            font-weight: 600;
            font-size: 36px;
            line-height: 28px;
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-left: 55px;
            @media (max-width: 1600px) {
                font-size: 30px;
            }
            @media (max-width: 1360px) {
                font-size: 25px;
            }
            @media (max-width: 960px) {
                font-size: 18px;
            }
            @media (max-width: 768px) {
                font-size: 14px;
            }
            @media (max-width: 501px) {
                font-size: 15px;
                line-height: 22px;
                margin-left: 0;
            }
        }
        &-edc{
            margin-left: 25px;
            @media (max-width: 1600px) {
                max-width: 150px;
            }
            @media (max-width: 1360px) {
                max-width: 127px;
            }
            @media (max-width: 960px) {
                max-width: 89px;
            }
            @media (max-width: 501px) {
                max-width: 85px;
                margin-left: 0;
            }
        }
        &-block{
            display: flex;
            flex: 1;
            align-items: flex-end;
            @media (max-width: 501px) {
                justify-content: space-around;
            }
        }
        @media (max-width: 960px) {
            margin-bottom: 15px;
        }
        @media (max-width: 501px) {
            margin-bottom: -45px;
            flex-direction: column;
        }
    }
}

.section-devices{
    background-color: #F6F8F9;
    position: relative;
    text-align: center;
    z-index: 4;
    &__h2{
        @include font-montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 62px;
        text-align: center;
        letter-spacing: 0.04em;
        color: #242E42;
        margin-top: 75px;
        &--yellow{
            color: #FFB600;
        }
        @media (max-width: 1600px) {
            font-size: 40px;
            line-height: 52px;
            margin-top: 25px;
        }
        @media (max-width: 1360px) {
            font-size: 34px;
            line-height: 44px;
        }
        @media (max-width: 960px) {
            font-size: 23px;
            line-height: 31px;
        }
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 25px;
        }
        @media (max-width: 501px) {
            font-size: 16px;
            line-height: 25px;
        }
    }
    &__mockup{
        margin-top: 50px;
        width: 100%;
        @media (max-width: 960px) {
            margin-top: 20px;
        }
    }
    &::after{
        content: '';
        display: block;
        z-index: -1;
        position: absolute;
        left: 0%;
        bottom: -100px;
        width: 100%;
        height: 200px;
        border-radius: 50%;
        background-color: #F6F8F9;
        @media (max-width: 501px) {
            bottom: -50px;
            width: 100%;
            height: 100px;
        }
    }

    .section-inner{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 150px 0 80px;
        @media (max-width: 960px) {
            padding: 200px 0 100px;
        }
    }
}

.section-features{
    background: linear-gradient(180.02deg, #FFB600 4.3%, #FFDC64 99.98%);
    position: relative;
    text-align: center;
    padding: 250px 0 120px;

    &__h2{
        @include font-montserrat;
        margin-top: 30px;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        letter-spacing: 0.04em;
        color: #242E42;
        @media (max-width: 1600px) {
            font-size: 40px;
            line-height: 46px;
        }
        @media (max-width: 1360px) {
            font-size: 34px;
            line-height: 39px;
        }
        @media (max-width: 960px) {
            font-size: 24px;
            line-height: 28px;
        }
        @media (max-width: 768px) {
            font-size: 20px;
            line-height: 28px;
        }
        @media (max-width: 501px) {
            font-size: 16px;
            line-height: 28px;
        }
    }
    &__features{
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        &-line{
            display: flex;
            justify-content: space-around;
            align-items: baseline;
            @media (max-width: 501px) {
                align-items: initial;
            }
        }
        @media (max-width: 501px) {
            margin-top: 40px;
        }
    }

    &__img{
        width: 35%;
        margin: 25px;
        img{
            width: 100%;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }

    &__feature{
        max-width: 375px;
        text-align: center;
        &-title{
            @include font-roboto;
            font-style: normal;
            font-weight: 900;
            font-size: 34px;
            color: #211D91;
        }
        &-text{
            @include font-roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #666666;
        }
        &-logo{
            max-width: 84px;
            margin: 0 auto;
            img{
                width: 100%;
            }
            @media (max-width: 501px) {
                max-width: 62px;
            }
        }
        @media (max-width: 1600px) {
            max-width: 320px;
            &-title{
                font-size: 28px;
                line-height: 42px;
            }
            &-text{
                font-size: 16.6552px;
                line-height: 23px;
            }
        }
        @media (max-width: 1360px) {
            max-width: 270px;
            &-title{
                font-size: 24.0689px;
                line-height: 36px;
            }
            &-text{
                font-size: 14.1581px;
                line-height: 20px;
            }
        }
        @media (max-width: 960px) {
            max-width: 200px;
            &-title{
                font-size: 17px;
                line-height: 25px;
            }
            &-text{
                font-size: 14px;
                line-height: 17px;
            }
        }
        @media (max-width: 768px) {
            &-title{
                font-size: 17px;
                line-height: 25px;
            }
            &-text{
                font-size: 14px;
                line-height: 17px;
            }
        }
        @media (max-width: 501px) {
            max-width: 140px;
            &-title{
                font-size: 15px;
                line-height: 25px;
                word-break: break-all;
            }
            &-text{
                font-size: 12px;
                line-height: 17px;
            }
        }
    }
    @media (max-width: 501px) {
        padding: 80px 0;
    }
}

.section-examples{
    .section-inner{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 150px 0 0 0;
    }
    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        color: #242E42;
        margin-bottom: 75px;
    }
    &__content{
        display: flex;
    }
    &__example{
        margin-bottom: 150px;
    }
    &__example:nth-child(even){
        .section-examples{
            &__content{
                flex-direction: row-reverse;
            }
            &__h2{
                text-align: right;
            }
        }
    }
    &__image{
        flex: 1;
        filter: drop-shadow(16px 16px 35px rgba(40, 56, 69, 0.1));
        border-radius: 0px 4px 4px 0px;
        img{
            width: 100%;
        }
    }
    &__text-wrap{
        flex: 1;
    }   
    &__text{
        @include font-montserrat;
        max-width: 580px;
        margin-top: 15px;
        font-weight: 600;
        font-size: 28px;
        letter-spacing: 1px;
        color: #2960A0;
        margin: 0 auto;
        span{
            color: #FFB600;
        }
    }
    @media (max-width: 1600px) {
        &__h2{
            font-size: 39.9725px;
            line-height: 47px;
        }  
        &__text{
            max-width: 480px;
            font-size: 22px;
            line-height: 40px;
        }
    }
    @media (max-width: 1360px) {
        &__h2{
            font-size: 33.9796px;
            line-height: 40px;
        }  
        &__text{
            max-width: 380px;
            font-size: 19px;
            line-height: 34px;
        }
    }
    @media (max-width: 960px) {
        &__h2{
            font-size: 33.9796px;
            line-height: 40px;
        }  
        &__text{
            max-width: 300px;
            font-size: 15px;
            line-height: 24px;
        }
    }
    @media (max-width: 768px) {
        .section-examples{
            &__h2{
                margin: 0!important;
                text-align: center!important;
            }       
            &__image{
                margin-right: auto!important;
                margin-left: auto!important;
            }     
        }
        &__h2{
            font-size: 23.9832px;
            line-height: 28px;
            margin: 0;
            text-align: center;
        }  
        &__image{
            margin: 0 auto;
            max-width: 530px;
            margin-top: 36px;
            margin: 36px auto 0;
        }
        &__text{
            font-size: 18px;
            line-height: 26px;
            max-width: 630px;
            text-align: center;
            margin-top: 26px;
        }
        &__example{
            margin-bottom: 72px;
        }
        &__content{
            display: block;
        }
    }
    @media (max-width: 501px) {
        &__h2{
            font-size: 16px;
            line-height: 20px;
        }  
        &__text{
            font-size: 12px;
            line-height: 17px;
        }
        .section-inner{
            padding: 75px 0 0 0;
            max-width: 341px;
        }
        &__image{
            max-width: 341px;
        }
    }
}

.section-clients{
    position: relative;

    &__h2{
        @include font-montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 55px;
        letter-spacing: 0.04em;
        color: #242E42;
        text-align: center;
        margin-top: 120px;
    }

    &__logos{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__logo{
        text-align: center;
        width: 20%;
        padding: 25px;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-img{
            width: 65%;
        }
    }
    
    .section-inner{
        max-width: 1740px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-bottom: 150px;
    }

    @media (max-width: 1600px) {
        &__h2{
            font-size: 33.9796px;
            line-height: 39px;
        }
    
        &__logo{
        }

        &__logo-img{
            width: 215px;
        }
    }
    @media (max-width: 1360px) {
        &__h2{
            font-size: 33.9796px;
            line-height: 39px;
        }
    
        &__logo{
            width: 20%;
        }
        
        &__logo-img{
            width: 80%;
        }
    }
    @media (max-width: 960px) {
        &__h2{
            font-size: 23.9832px;
            line-height: 28px;
            margin-top: 30px;
            margin-bottom: 60px;
        }
        &__logos{
            justify-content: center;
        }
        &__logo{
            width: 20%;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
        
        &__logo-img{
            width: 80%;
        }
        .section-inner{
            padding-bottom: 75px;
        }
    }
    @media (max-width: 768px) {

        &__h2{
            font-size: 20px;
            line-height: 28px;
        }
        &__logo{
            width: 25%;
            margin-top: 10px;
            padding-top: 0;
            padding-bottom: 0;
        }
        
        &__logo-img{
            width: 60%;
        }

    }
    @media (max-width: 501px) {
        &__logo{
            width: 49%;
            margin-top: 50px;
        }
        
        &__logo-img{
            width: 85%;
        }
    }
}

.section-booking{
    text-align: center;
    background-color: #F6F8F9;
    padding: 120px 0;

    &__logo{
        display: flex;
        align-items: center;
        justify-content: center;
        &-wrapper{
            display: flex;
        }
        &-img{
            width: 60px;
            height: 60px;
            margin-right: 25px;
        }
        &-text{
            @include font-roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 24.8198px;
            line-height: 29px;
            letter-spacing: 0.05em;
            margin-bottom: 0;
        }
    }
    &__text{
        @include font-roboto;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        color: #222A41;
        margin-bottom: 80px;
        margin-top: 25px;
        span{
            color: rgb(255,182,0);
        }
    }
    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 2px;
        color: #242E42;
        margin-top: 26px;
    }

    &__button{
        @include font-roboto;
        width: 450px;
        margin: 0 auto;
        font-weight: 500;
        font-size: 21.3333px;
        line-height: 25px;
        border: 2.83163px solid #FFB600;
        border-radius: 30px;
        letter-spacing: 1.06667px;
        color:#1E1E1E;
        padding: 13px 23px;
        box-sizing: border-box;
        outline: none;
        transition: 0.1ms;
        outline: none;
        &:hover{
            transition: 0.1ms;
            background-color:#2574CF;
            border-color: #2574CF;
            text-decoration: none;
            color: #fff;
        }
        &:focus{
            outline: none;
        }
        &:active{
            outline: none;
            transition: 0.1ms;
            background-color: #2960A0;
            border-color:#2960A0;
        }
    }
    .section-inner{
        max-width: none;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    
    @media (max-width: 1600px) {
        &__logo{
            &-img{
                width: 49px;
                height: auto;
                margin-right: 10px;
            }
            &-text{
                font-size: 20.6689px;
                line-height: 24px;
            }
        }

        &__h2{
            font-size: 39.9725px;
            line-height: 47px;
        }

        &__text{
            font-size: 19.9863px;
            line-height: 32px;
            margin-bottom: 50px;
        }

        &__button{
            font-size: 17.7656px;
            line-height: 21px;
            padding: 15px 25px;
        }
    }
    @media (max-width: 1360px) {
        padding: 60px 0;
        &__logo{
            &-img{
                width: 42px;
                height: auto;
                margin-right: 10px;
            }
            &-text{
                font-size: 17.5701px;
                line-height: 21px;
            }
        }

        &__h2{
            font-size: 33.9796px;
            line-height: 40px;
        }

        &__text{
            font-size: 16.9898px;
            line-height: 27px;
            margin-bottom: 40px;
        }

        &__button{
            font-size: 15.102px;
            line-height: 18px;
            padding: 14px 0;
            margin-top: 10px;
            max-width: 330px;
        }
    }
    @media (max-width: 960px) {
        padding: 60px 160px;
        &__logo{
            &-img{
                max-width: 30px;
                height: auto;
                margin-right: 5px;
            }
            &-text{
                font-size: 12.4012px;
                line-height: 15px;
            }
        }

        &__h2{
            font-size: 23.9832px;
            line-height: 28px;
            margin-top: 12px;
        }

        &__text{
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 20px;
            margin-top: 8px;
        }

        &__button{
            font-size: 10.6592px;
            line-height: 12px;
            padding: 8px 0;
            margin-top: 10px;
            max-width: 210px;
        }
    }
    @media (max-width: 768px) {
        padding: 60px 5%;
    }
}

.section-vPresent{
    padding: 150px 0 50px;
    position: relative;
    .section-inner{
        position: relative;
        z-index: 10;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__description{
        flex: 2;
        text-align: center;
        max-width: 565px;
        margin-right: 50px;
        
    }

    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        line-height: 60px;
        letter-spacing: 0.04em;
        color: #242E42;
        margin-bottom: 35px;
        margin-top: 20px;
        &-yellow{
            color: #FFB600;
        }
    }

    &__text{
        @include font-roboto;
        font-weight: normal;
        font-size: 24px;
        line-height: 38px;
        color: #222A41;
        margin-bottom: 45px;
        text-align: left;
    }

    &__button{
        @include font-roboto;
        background: #FFB600;
        border-radius: 30px;
        font-weight: 500;
        font-size: 21.3333px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 1.06667px;
        color: #FFFFFF;
        border: none;
        padding: 12px 40px;
        display: inline-block;
        img{
            margin-right: 7.5px;
        }
        &:hover{
            transition: 0.1ms;
            background-color:#2574CF;
            border-color: #2574CF;
            color: #fff;
        }
        &:focus{
            outline: none;
        }
        &:active{
            outline: none;
            transition: 0.1ms;
            background-color: #2960A0;
            border-color:#2960A0;
        }
    }
    &__video{
        flex: 3;
        height: 544px;
        position: relative;
        background: url('../img/vPresentPlaceholder.png') no-repeat 50% 50%;
        background-size: cover;
        &-embed{
            position: relative;
            z-index: -100;
        }
    }
    input:checked + iframe{
        z-index: 100;
    }
    &__v-text{
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
        p{
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            letter-spacing: 2.1px;
            color: #FFFFFF;
        }
    }
    &__playbtn{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        &-icon{
            width: 120px;
            height: 120px;
            background-color: #fff;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            &::before{
                content: '';
                position: absolute;
                left: 25%;
                bottom: 50%;
                transform: translate(50%,50%);
                border: 15px solid transparent;
                border-left: 25px solid #FF9800;
            }
            &:hover::before{
                border-left-color: #fff;
            }
            &:hover{
                background-color: #FFB600;
            }
            &:active{
                background-color: #FF9800;
            }
        }
    }
    &::before{
        content: '';
        display: block;
        z-index: 1;
        position: absolute;
        left: 0%;
        bottom: -150px;
        width: 100%;
        height: 300px;
        border-radius: 50%;
        background-color: #fff;
    }

    @media (max-width: 1600px) {
        &__playbtn-icon{
            width: 99px;
            height: 99px;
            &::before{
                border: 12.5px solid transparent;
                border-left: 20px solid #FF9800;
            }
        }
        &__v-text p{
            font-size: 33.3104px;
            line-height: 40px;
        }
        &__logo{
            max-width: 50px;
            margin: 0 auto;
            img{
                width: 100%;
            }
        }

        &__video{
            height: 453.02px;
        }
        &__h2{
            font-size: 39.9725px;
            line-height: 50px;
        }

        &__text{
            font-size: 19.9863px;
            line-height: 32px;
        }

        &__button{
            padding: 10px 20px;
            font-size: 17.7656px;
            line-height: 21px;
        }
    }
    @media (max-width: 1360px) {
        &__playbtn-icon{
            width: 84px;
            height: 84px;
            &::before{
                border: 12px solid transparent;
                border-left: 17px solid #FF9800;
            }
        }
        &__v-text p{
            font-size: 28.3163px;
            line-height: 34px;
        }
        &__logo{
            max-width: 42px;
        }
        &__h2{
            font-size: 33.9796px;
            line-height: 42px;
        }
        &__video{
            height: 385.1px;
        }
        &__text{
            font-size: 16.9898px;
            line-height: 27px;
        }

        &__button{
            padding: 10px 20px;
            font-size: 15.102px;
            line-height: 18px;
        }
    }
    @media (max-width: 960px) {
        &__playbtn-icon{
            width: 60px;
            height: 60px;
            &::before{
                border: 8px solid transparent;
                border-left: 14px solid #FF9800;
            }
        }
        &__v-text p{
            font-size: 19.986px;
            line-height: 24px;
        }

        &__logo{
            max-width: 29px;
        }
        &__h2{
            font-size: 23.9832px;
            line-height: 30px;
        }
        &__video{
            height: 271.81px;
        }
        &__text{
            font-size: 11.9916px;
            line-height: 19px;
        }

        &__button{
            padding: 6px 17px;
            font-size: 10.6592px;
            line-height: 12px;
        }
    }
    @media (max-width: 768px) {
        padding: 75px 0 50px;
        .section-inner{
            flex-direction: column;
        }
        &__playbtn-icon{
            width: 73px;
            height: 73px;
            &::before{
                border: 10px solid transparent;
                border-left: 18px solid #FF9800;
            }
        }
        &__v-text p{
            font-size: 24.3978px;
            line-height: 29px;
        }
        &__description{
            margin-right: 0;
        }
        &__logo{
            max-width: 29px;
        }
        &__h2{
            font-size: 20px;
            line-height: 30px;
            br {
                display: none;
            }
        }
        &__video{
            width: 100%;
            height: 331.81px;
            margin-top: 44px;
        }
        &__text{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 32px;
        }

        &__button{
            padding: 6px 17px;
            font-size: 10.6592px;
            line-height: 12px;
        }
    }
    @media (max-width: 501px) {
        &__playbtn-icon{
            width: 47px;
            height: 47px;
            &::before{
                border: 6.5px solid transparent;
                border-left: 10px solid #FF9800;
            }
        }
        &__v-text p{
            font-size: 15.7768px;
            line-height: 19px;
        }
        &__h2{
            font-size: 16px;
            line-height: 24px;
        }
        &__video{
            width: 100%;
            height: 214px;
            margin-top: 30px;
        }
        &__text{
            font-size: 14px;
            line-height: 24px;
        }

        &__button{
            padding: 10px 25px;
            font-size: 10.6592px;
            line-height: 12px;
        }
        &::before{
            bottom: -50px;
            width: 100%;
            height: 100px;
        }
    }
}
.section-youtube{
    position: relative;
    &::before{
        content: '';
        display: block;
        z-index: 1;
        position: absolute;
        left: 0%;
        bottom: -150px;
        width: 100%;
        height: 300px;
        border-radius: 50%;
        background-color: #fff;
    }
    .section-inner{

        text-align: center;
        padding: 40px 0 50px;
    }
    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
    }
    &__button{
        display: inline-block;
        background: #FFB600;
        border-radius: 30px;
        padding: 13px 40px;
        font-weight: 500;
        font-size: 21.3333px;
        line-height: 25px;
        margin-top: 50px;
        color: #fff;
        img{
            margin-right: 15px;
        }
        &:hover{
            transition: 0.1ms;
            background-color:#2574CF;
            border-color: #2574CF;
            color: #fff;
        }
        &:focus{
            outline: none;
        }
        &:active{
            outline: none;
            transition: 0.1ms;
            background-color: #2960A0;
            border-color:#2960A0;
        }
    }
    @media (max-width: 1600px) {
        .section-inner{
            text-align: center;
            padding: 60px 0 120px;
        }
        &__h2{
            font-size: 40px;
            line-height: 52px;
        }
        &__button{
            padding: 10px 30px;
            font-size: 18px;
            line-height: 25px;
            margin-top: 40px;
        }
    }
    @media (max-width: 1360px) {
        .section-inner{
            text-align: center;
            padding: 60px 0 120px;
        }
        &__h2{
            font-size: 36px;
            line-height: 48px;
        }
        &__button{
            padding: 8px 25px;
            font-size: 16px;
            line-height: 22px;
            margin-top: 40px;
            img{
                width: 25px;
            }
        }
    }
    @media (max-width: 960px) {
        .section-inner{
            padding: 60px 0 120px;
        }
        &__h2{
            font-size: 28px;
            line-height: 48px;
        }
        &__button{
            margin-top: 30px;
        }
    }
    @media (max-width: 768px) {
        .section-inner{
            padding: 60px 0 120px;
        }
        &__h2{
            font-size: 24px;
            line-height: 36px;
        }
        &__button{
            padding: 8px 25px;
            font-size: 14px;
            line-height: 22px;
            margin-top: 25px;
            img{
                width: 24px;
            }
        }
    }
    @media (max-width: 501px) {
        .section-inner{
            text-align: center;
            padding-top: 150px;
            padding-bottom: 100px;
        }
        &__hm{
            font-size: 8.99371px;
            line-height: 207.9%;
        }
        &__h2{
            font-size: 16px;
            line-height: 20px;
        }
        &__text{
            font-size: 12px;
            line-height: 18px;
        }
        &__product{
            max-width: 325px;
            text-align: center;
            &-icon{
                margin: 0 auto;
            }
            &-title{
                margin-top: 15px;
            }
            &-text{
                margin-top: 15px;
            }
        }
    }
}
.section-products{
    background: url('../img/products-bg.png') 50% no-repeat;
    background-size: cover;
    .section-inner{
        text-align: center;
        padding-top: 320px;
        padding-bottom: 100px;
    }
    &__hm{
        @include font-montserrat;
        font-weight: 800;
        font-size: 18px;
        letter-spacing: 8px;
        text-transform: uppercase;
        color: #F61067;
    }
    &__h2{
        @include font-montserrat;
        font-weight: bold;
        font-size: 60px;
        letter-spacing: 2px;
        color: #FCFCFC;
        margin-top: 20px;
    }
    &__text{
        @include font-roboto;
        font-weight: 900;
        font-size: 24px;
        letter-spacing: 0.04em;
        color: #FCFCFC;
        margin: 75px auto 0;
        max-width: 1230px;
        text-align: center;
    }
    &__products{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 200px;
        &-line{
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
        }
    }
    &__product{
        max-width: 490px;
        text-align: left;
        &-title{
            @include font-roboto;
            margin-top: 25px;
            font-weight: 500;
            font-size: 30px;
            line-height: 45px;
            font-weight: bold;
            color: #00AF93;
            &--red{
                color: #F61067;
            }
            &--orange{
                color: #F67110;
            }
        }
        &-text{
            margin-top: 25px;
            @include font-montserrat;
            font-size: 24px;
            line-height: 163.35%;
            color: #FCFCFC;
        }
        &-icon{

        }
    }

    @media (max-width: 1600px) {
        .section-inner{
            padding-top: 220px;
        }
        &__hm{
            font-size: 14.9897px;
            line-height: 207.9%;
        }
        &__h2{
            font-size: 49.9657px;
            line-height: 61px;
            margin-top: 8px;
        }
        &__text{
            font-size: 19.9863px;
            line-height: 163.35%;
            margin-top: 30px;
        }
        &__products{
            margin-top: 30px;
        }
        &__product{
            max-width: 336px;
            margin-top: 50px;
            &-icon{
                width: 80px;
                img{
                    width: 100%;
                }
            }
            &-title{
                font-size: 24.9828px;
                line-height: 29px;
            }
            &-text{
                font-size: 19.9863px;
                line-height: 163.35%;
            }
        }
    }
    @media (max-width: 1360px) {
        &__hm{
            font-size: 12.7423px;
            line-height: 207.9%;
        }
        &__h2{
            font-size: 42.4744px;
            line-height: 52px;
        }
        &__text{
            font-size: 16.9898px;
            line-height: 163.35%;
        }
        &__product{
            max-width: 310px;
            &-icon{
                width: 67px;
            }
            &-title{
                font-size: 21.2372px;
                line-height: 25px;
            }
            &-text{
                font-size: 16.9898px;
                line-height: 163.35%;
            }
        }
    }
    @media (max-width: 960px) {
        &__hm{
            font-size: 8.99371px;
            line-height: 207.9%;
        }
        &__h2{
            font-size: 29.979px;
            line-height: 37px;
        }
        &__text{
            font-size: 14px;
            line-height: 163.35%;
        }
        &__product{
            max-width: 220px;
            &-icon{
                width: 48px;
            }
            &-title{
                font-size: 14.9895px;
                line-height: 18px;
            }
            &-text{
                font-size: 13px;
                line-height: 163.35%;
            }
        }
    }
    @media (max-width: 768px) {
        &__hm{
            font-size: 8.99371px;
            line-height: 207.9%;
        }
        &__h2{
            font-size: 20px;
            line-height: 24px;
        }
        &__text{
            font-size: 16px;
            line-height: 24px;
        }
        &__product{
            max-width: 240px;
            &-icon{
                width: 48px;
            }
            &-title{
                font-size: 14.9895px;
                line-height: 18px;
            }
            &-text{
                font-size: 13px;
                line-height: 163.35%;
            }
        }
    }
    @media (max-width: 501px) {
        .section-inner{
            text-align: center;
            padding-top: 150px;
            padding-bottom: 100px;
        }
        &__hm{
            font-size: 8.99371px;
            line-height: 207.9%;
        }
        &__h2{
            font-size: 16px;
            line-height: 20px;
        }
        &__text{
            font-size: 12px;
            line-height: 18px;
        }
        &__product{
            max-width: 325px;
            text-align: center;
            &-icon{
                margin: 0 auto;
            }
            &-title{
                margin-top: 15px;
            }
            &-text{
                margin-top: 15px;
            }
        }
    }
}

.section-reviews{
    &__item{
        display: flex;
    }
    &__img{
        position: relative;
        z-index: 2;
        flex: 4;
        height: 850px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            position: relative;
            z-index: 2;
            max-width: 716px;
        }
        &::before{
            z-index: 1;
            content: '';
            display: block;
            position: absolute;
            width: 60%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: #FDC443;
            border-radius: 8px;
        }
    }
    &__review{
        flex: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        color: #242E42;
    }
    &__quotes{
        @include font-montserrat;
        font-weight: 900;
        font-size: 151.148px;
        margin-top: 50px;
        line-height: 100px;
        color: #F4933C;
        margin-bottom: 0;
    }
    &__text{
        @include font-montserrat;
        font-weight: normal;
        font-size: 20.2431px;
        line-height: 32px;
        color: #222A41;
    }
    &__author{
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        border-left: 3px solid #F4933C;
        margin-top: 25px;
        &-name{
            @include font-montserrat;
            font-weight: 900;
            font-size: 24.2917px;
            line-height: 36px;
            color: #F4933C;
        }
        &-prof{
            @include font-roboto;
            font-weight: normal;
            font-size: 17.544px;
            line-height: 22px;
            color: #6D7783;
        }
    }
    &__controls{
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100px;
        z-index: 15;
        top: 800px;
        left: 43.5%;
    }
    &__next{
        position: initial;
        width: 45px;
        height: 40px;
    }
    &__prev{
        position: initial;
        img{
            transform: scale(-1,1);
        }
    }
    .section-inner{
        padding: 150px 0;
        min-height: 675px;
    }

    @media (max-width: 1600px) {
        &__img{
            height: 730px;
            img{
                max-width: 600px;
            }
        }
        &__h2{
            font-size: 39.9725px;
            line-height: 61px;
        }
        &__quotes{
            font-size: 125.871px;
            line-height: 142px;
        }
        &__text{
            font-size: 19.9863px;
            line-height: 27px;
        }
        &__author{
            &-name{
                font-size: 20.2292px;
                line-height: 30px;
            }
            &-prof{
                font-size: 14.61px;
                line-height: 18px;
            }
        }
        &__next{
            width: 45px;
            height: 40px;
            img{
                width: 100%;
            }
        }
        &__prev{
            width: 45px;
            height: 40px;
            img{
                transform: scale(-1,1);
                width: 100%;
            }
        }
    }
    @media (max-width: 1360px) {
        &__img{
            height: 620px;
            img{
                max-width: 507px;
            }
        }
        &__h2{
            font-size: 33.9796px;
            line-height: 52px;
        }
        &__quotes{
            font-size: 106.999px;
            line-height: 120px;
        }
        &__controls{
            width: 75px;
            top: 575px;
        }
        &__text{
            font-size: 16.9898px;
            line-height: 23px;
        }
        &__author{
            &-name{
                font-size: 17.1963px;
                line-height: 26px;
            }
            &-prof{
                font-size: 13px;
                line-height: 15px;
            }
        }
        &__next{
            width: 27px;
            height: 40px;
        }
        &__prev{
            width: 27px;
            height: 40px;
        }
        .section-inner{
            padding: 90px 0;
        }
    }
    @media (max-width: 960px) {
        &__img{
            height: 438.29px;
            img{
                max-width: 358px;
            }
        }
        &__h2{
            font-size: 23.9832px;
            line-height: 36px;
        }
        &__quotes{
            font-size: 75.5215px;
            line-height: 85px;
        }
        &__controls{
            width: 75px;
        }
        &__text{
            font-size: 13px;
            line-height: 18px;
        }
        &__controls{
            left: 40.5%;
            top: 400px;
        }
        &__author{
            &-name{
                font-size: 12.1374px;
                line-height: 18px;
            }
            &-prof{
                font-size: 10px;
                line-height: 11px;
            }
        }
        &__next{
            width: 20px;
        }
        &__prev{
            width: 20px;
        }
        .section-inner{
            padding: 90px 0;
        }
    }
    @media (max-width: 768px) {
        &__img{
            height: 341.11px;
            img{
                max-width: 278px;
            }
        }
        &__h2{
            font-size: 20px;
            line-height: 29px;
        }
        &__quotes{
            font-size: 59.5604px;
            line-height: 40px;
            margin-top: 5px;
        }
        &__text{
            font-size: 13px;
            line-height: 18px;
        }
        &__controls{
            left: 40.5%;
            top: 310px;
            width: 50px;
        }
        &__author{
            margin-top: 10px;
            &-name{
                font-size: 9.5722px;
                line-height: 14px;
            }
            &-prof{
                font-size: 10px;
                line-height: 9px;
            }
        }
        &__next{
            width: 15px;
        }
        &__prev{
            width: 15px;
        }
        .section-inner{
            padding: 65px 0;
        }
    }
    @media (max-width: 501px) {
        &__item{
            flex-direction: column;
        }
        &__img{
            height: 387.11px;
            img{
                max-width: 316px;
            }
            &::before{

            }
        }
        &__h2{
            font-size: 20px;
            line-height: 29px;
            margin-top: 20px;
        }
        &__quotes{
            font-size: 59.5604px;
            line-height: 40px;
            margin-top: 20px;
        }
        &__text{
            font-size: 13px;
            line-height: 18px;
        }
        &__controls{
            right: 10%;
            left: initial;
            top: 332px;
            width: 50px;
        }
        &__author{
            margin-top: 10px;
            &-name{
                font-size: 14px;
                line-height: 16px;
            }
            &-prof{
                font-size: 12px;
                line-height: 15px;
                margin-top: 5px;
            }
        }
        &__next{
            width: 15px;
        }
        &__prev{
            width: 15px;
        }
        .section-inner{
            padding: 65px 0;
        }
    }
}

.section-access{
    background: url('../img/s-access-bg.svg') no-repeat 50% 50%;
    background-size: cover;

    &__line{
        display: flex;
        justify-content: center;
        margin-top: 120px;
    }
    &__img{
        width: 20%;
        margin-right: 10%;
        img{
            width: 100%;
        }
    }
    &__h2{
        @include font-montserrat;
        font-weight: bold;
        font-size: 28px;
        letter-spacing: 0.04em;
        color: #242E42;
    }
    &__list{
        max-width: 610px;
    }
    &__list-item{
        @include font-montserrat;
        font-weight: 600;
        font-size: 26px;
        text-align: left;
        line-height: 48px;
        color: #242E42;
        margin-bottom: 40px;
    }
    .section-inner{
        padding: 80px 0;
        text-align: center;
    }
    @media (max-width: 1600px) {
        &__line{
            margin-top: 80px;
        }
        &__h2{
            font-size: 24px;
            line-height: 36px;
        }
        &__list{
            max-width: 500px;
        }
        &__list-item{
            font-size: 22px;
            line-height: 32px;
        }
        .section-inner{
            padding: 60px 0;
        }
    }
    @media (max-width: 1360px) {
        &__line{
            margin-top: 70px;
        }
        &__h2{
            font-size: 22px;
            line-height: 30px;
        }
        &__list{
            max-width: 470px;
        }
        &__list-item{
            font-size: 20px;
            line-height: 25px;
        }
        .section-inner{
            padding: 45px 0;
        }
    }
    @media (max-width: 960px) {
        &__line{
            margin-top: 60px;
        }
        &__h2{
            font-size: 16px;
            line-height: 20px;
        }
        &__list{
            max-width: 280px;
        }
        &__list-item{
            font-size: 16px;
            line-height: 36px;
            margin-bottom: 10px;
        }
        .section-inner{
            padding: 25px 0;
        }
    }
    @media (max-width: 768px) {
        &__line{
            margin-top: 40px;
        }
        &__h2{
            font-size: 16px;
            line-height: 36px;
        }
        &__list{
            max-width: 280px;
        }
        &__list-item{
            font-size: 14px;
            line-height: 28px;
        }
        .section-inner{
            padding: 25px 0;
        }
    }
    @media (max-width: 501px) { 
        &__line{
            margin-top: 30px;
        }
        &__h2{
            font-size: 14px;
            line-height: 28px;
        }
        &__list{
            max-width: 203px;
        }
        &__list-item{
            font-size: 12px;
            line-height: 16px;
        }
        .section-inner{
            padding: 25px 0;
        }
    }
}
.section-mobile{
    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        letter-spacing: 2px;
        color: #242E42;
    }
    &__text{
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #000000;
        text-align: left;
    }
    &__left{
        margin-left: 6%;
        margin-right: 15%;
        flex: 8;
    }
    &__row{
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
    &__links{
        display: flex;
        justify-content: left;
        margin-top: 56px;

    }
    &__link{
        margin-right: 52px;
        img{
            width: 100%;
        }
    }
    &__img-wrap{
        flex: 2;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .section-inner{
        padding: 100px 0;
        text-align: center;
    }
    @media (max-width: 1600px) {
        .section-inner{
            padding: 60px 0;
        }
        &__h2{
            font-size: 39.9725px;
            line-height: 47px;
        }

        &__text{
            font-size: 19.9863px;
            line-height: 32px;
            margin-bottom: 50px;
        }
        &__link {
            width: 150px;
        }
    }
    @media (max-width: 1360px) {
        .section-inner{
            padding: 45px 0;
        }

        &__h2{
            font-size: 33.9796px;
            line-height: 40px;
        }

        &__text{
            font-size: 16.9898px;
            line-height: 27px;
            margin-bottom: 40px;
        }
        &__link {
            width: 125px;
        }
        &__left{
            margin-right: 10%;
        }
    }
    @media (max-width: 960px) {
        .section-inner{
            padding: 25px 0;
        }

        &__h2{
            font-size: 23.9832px;
            line-height: 28px;
            margin-top: 12px;
        }
        &__links{
            margin-top: 40px;
        }
        &__text{
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 20px;
            margin-top: 8px;
        }
        &__link {
            width: 100px;
        }
        &__left{
            margin-right: 7%;
        }
    }
    @media (max-width: 768px) {
        &__row{
            margin-top: 25px;
        }
        &__img-wrap{
            display: none;
        }
        &__h2{
            font-size: 23.9832px;
            line-height: 28px;
        }  
        &__text{
            font-size: 16px;
            line-height: 26px;
            text-align: center;
        }
        &__links{
            justify-content: space-around;
        }
        &__link{
            margin: 0;
        }
    }
    @media (max-width: 501px) {
        &__h2{
            font-size: 16px;
            line-height: 20px;
        }  
        &__text{
            font-size: 12px;
            line-height: 17px;
        }
    }
}

.section-form{
    position: relative;
    z-index: 100;

    &__logo{
        display: flex;
        align-items: center;
        justify-content: center;
        &-wrapper{
            display: flex;
        }
        &-img{
            width: 60px;
            height: 60px;
            margin-right: 25px;
        }
        &-text{
            @include font-roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 24.8198px;
            line-height: 29px;
            letter-spacing: 0.05em;
            margin-bottom: 0;
        }
    }

    &__h2{
        @include font-montserrat;
        font-weight: 900;
        font-size: 48px;
        line-height: 56px;
        letter-spacing: 2px;
        color: #242E42;
        margin-top: 30px;
    }

    &__h-text{
        @include font-roboto;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-top: 30px;
    }

    &__contacts{
        display: flex;
        justify-content: space-around;
        margin-top: 40px;
    }
    &__contact{
        display: flex;
        align-items: baseline;
        &-icon{
            margin-right: 5px;
        }
        &-text{
            @include font-roboto;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #000000;
            a {
                color: inherit;
            }
        }
    }
    &__form{
        text-align: left;
        color: #fff;
        margin-top: 25px;
        background: #FDC443;
        box-shadow: 16px 16px 35px rgba(40, 56, 69, 0.1);
        border-radius: 8px;
        padding: 64px;
        &-line{
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
        }
        &-field{
            display: flex;
            flex-direction: column;
            text-align: left;
            width: 48%;
        }
        &-input{
            padding: 17px 20px;
            height: 56px;
            border-radius: 8px;
            border: none;
            outline: none;
            box-sizing: border-box;
            border: 1px solid #C3CAD9;
            transition: 0.1s;
            font-family: Roboto;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #183B56;
            &:focus{
                border: 1px solid #097ABD;
                transition: 0.1s;
            }
        }
        &-textarea{
            padding: 17px 20px;
            width: 100%;
            border-radius: 8px;
            border: none;
            outline: none;
            box-sizing: border-box;
            border: 1px solid #C3CAD9;
            transition: 0.1s;
            font-family: Roboto;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #183B56;
            resize: none;
            &:focus{
                border: 1px solid #097ABD;
                transition: 0.1s;
            }
        }
        &::placeholder{
            color: #959EAD;
        }
    }
    &__license{
        @include font-roboto;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        color: #FFFFFF;
        margin-top: 10px;
        input {
            position: relative;
            top: 5px;
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
    &__submit{
        @include font-roboto;
        font-weight: 500;
        font-size: 21.3333px;
        line-height: 25px;
        letter-spacing: 1.06667px;
        color: #FFFFFF;
        background: #2574CF;
        border-radius: 30px;
        border: none;
        padding: 14px 30px;
        &:hover{
            transition: 0.1ms;
            background-color:#FFFFFF;
            border-color:#FFFFFF;
            color: #000;
        }
        &:focus{
            outline: none;
        }
        &:active{
            outline: none;
            transition: 0.1ms;
            color: #000;
            background-color: #DADADA;
            border-color:#DADADA;
        }
    }
    .section-inner{
        padding: 200px 0 0 0;
        text-align: center;
        max-width: 960px;
        height: 1080px;
    }

    @media (max-width: 1600px) {
        &__logo{
            &-img{
                width: 49px;
                height: auto;
                margin-right: 15px;
            }
            &-text{
                font-size: 20.6689px;
                line-height: 24px;
            }
        }
    
        &__h2{
            font-size: 39.9725px;
            line-height: 47px;
        }
    
        &__h-text{
            font-size: 19.9863px;
            line-height: 27px;
        }
    
        &__contacts{
            margin-top: 40px;
        }
        &__contact{
            &-icon{
                margin-right: 5px;
            }
            &-text{
                font-size: 14.9897px;
                line-height: 20px;
            }
        }
        &__form{
            &-lable{
                font-size: 13.3242px;
                line-height: 16px;
            }
            &-input{
                font-size: 13.3242px;
                line-height: 16px;
                padding: 14px 16px;
            }
        }
        &__license{
            font-size: 13.3242px;
            line-height: 23px;
            input {
                width: 15px;
                height: 15px;
            }
        }
        &__submit{
            font-size: 17.7656px;
            line-height: 21px;
            padding: 10px 20px;
        }
        .section-inner{
            padding: 150px 0 0 0;
            max-width: 800px;
            max-height: 955px;
        }
    }
    @media (max-width: 1360px) {
        &__logo{
            &-img{
                width: 42px;
                margin-right: 15px;
            }
            &-text{
                font-size: 17.5701px;
                line-height: 21px;
            }
        }
    
        &__h2{
            font-size: 33.9796px;
            line-height: 40px;
        }
    
        &__h-text{
            font-size: 16.9898px;
            line-height: 23px;
        }
        &__contacts{
            margin-top: 40px;
        }
        &__contact{
            &-icon{
                margin-right: 5px;
            }
            &-text{
                font-size: 12.7423px;
                line-height: 17px;
            }
        }
        &__form{
            &-line{
                margin-bottom: 15px;
            }
            &-label{
                font-size: 11.3265px;
                line-height: 13px;
            }
            &-input{
                font-size: 11.3265px;
                line-height: 13px;
                padding: 14px 16px;
                height: 40px;
            }
            &-textarea{
                font-size: 11.3265px;
                line-height: 13px;
            }
            &-ta-label{
                font-size: 11.3265px;
                line-height: 13px;
            }
        }
        &__license{
            font-size: 11.3265px;
            line-height: 20px;
            input {
                width: 15px;
                height: 15px;
            }
        }
        &__submit{
            font-size: 15.102px;
            line-height: 18px;
            padding: 10px 18px;
        }
        .section-inner{
            padding: 80px 0 0 0;
            max-width: 680px;
            max-height: 790px;
        }
    }
    @media (max-width: 960px) {
        &__logo{
            &-img{
                width: 30px;
                margin-right: 10px;

            }
            &-text{
                font-size: 12.4012px;
                line-height: 15px;
            }
        }
    
        &__h2{
            font-size: 23.9832px;
            line-height: 28px;
            margin-top: 15px;
        }
    
        &__h-text{
            font-size: 13px;
            line-height: 16px;
            margin-top: 14px;
        }
        &__contacts{
            margin-top: 20px;
        }
        &__contact{
            &-icon{
                margin-right: 2.5px;
                img{
                    width: 12px;
                }
            }
            &-text{
                font-size: 10px;
                line-height: 12px;
                margin-bottom: 0;
            }
        }
        &__form{
            padding: 30px;
            &-line{
                margin-bottom: 10px;
            }
            &-label{
                font-size: 10px;
                line-height: 12px;
            }
            &-input{
                font-size: 12px;
                line-height: 14px;
                padding: 7.5px 10px;
                height: 37px;
            }
            &-textarea{
                font-size: 12px;
                line-height: 14px;
            }
            &-ta-label{
                font-size: 10px;
                line-height: 12px;
            }
        }
        &__license{
            font-size: 10px;
            line-height: 14px;
        }
        &__submit{
            font-size: 10.6592px;
            line-height: 12px;
            padding: 6.5px 13px;
        }
        .section-inner{
            padding: 40px 0 0 0;
            max-width: 480px;
            max-height: 615px;
        }
    }
    @media (max-width: 768px) {
        &__logo{
            &-img{
                width: 31px;
                margin-right: 10px;
            }
            &-text{
                font-size: 12.927px;
                line-height: 15px;
            }
        }
    
        &__h2{
            font-size: 20px;
            line-height: 29px;
            margin-top: 15px;
        }
    
        &__h-text{
            font-size: 13.5511px;
            line-height: 17px;
            margin-top: 15px;
        }

        &__contacts{
            margin-top: 26px;
        }

        &__contact{
            &-text{
                font-size: 10.4239px;
                line-height: 12px;
            }
        }
        &__form{
            padding: 30px;
            &-line{
                margin-bottom: 10px;
            }
            &-label{
                font-size: 10px;
                line-height: 12px;
            }
            &-input{
                font-size: 12px;
                line-height: 14px;
                padding: 7.5px 10px;
                height: 37px;
            }
            &-textarea{
                font-size: 12px;
                line-height: 14px;
            }
            &-ta-label{
                font-size: 10px;
                line-height: 12px;
            }
        }
        &__license{
            font-size: 10px;
            line-height: 14px;
        }
        &__submit{
            font-size: 10.6592px;
            line-height: 12px;
            padding: 6.5px 13px;
        }
        .section-inner{
            padding: 40px 0 0 0;
            max-width: 480px;
            max-height: 615px;
        }
    }
    @media (max-width: 501px) {
        .section-inner{
            max-width: 343px;
        }
        &__contacts{
            flex-direction: column;
            align-items: center;
        }
        &__form{
            &-line{
                flex-direction: column;
            }
            &-field{
                width: 100%;
            }
        }
        &__submit{
            width: 100%;
            font-size: 14px;
            line-height: 16px;
            padding: 10px 24px;
        }
        .section-inner{
            max-height: 720px;
        }
    }
}

.section-footer{
    background: url('../img/footerbg.png') no-repeat 50% 50%;
    background-size: cover;
    .section-inner{
        padding: 200px 0 0;
        z-index: 99;
    }
    &__row{
        margin-top: 100px;
        display: flex;
        justify-content: space-around;
        border-bottom: 2px solid rgba(255,255,255,0.5);
        padding-bottom: 30px;
    }
    &__block-header{
        @include font-roboto;
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 1px;
        color: #FFE99D;
    }
    &__list{
        list-style-type: none;
        padding: 0;
        margin-top: 30px;
        &-item{
            @include font-roboto;
            font-weight: normal;
            font-size: 16px;
            line-height: 36px;
            margin-bottom: 5px;
            color: #FFFFFF;
            a {
                color: inherit;
                &:hover{
                    color: #dddddd;
                }
            }
        }
    }
    &__social{
        display: flex;
        margin-bottom: 35px;
        align-items: center;
        &--hm{
            .section-footer{
                &__social{
                    &-logo{
                        padding: 7.5px 10px;
                        background-color: #fff;
                        border-radius: 4px;
                    }
                    &-text{
                        font-size: 12px;
                    }
                }
            }
        }

        &-logo{
            margin-right: 15px;
        }

        &-text{
            @include font-roboto;
            font-size: 16px;
            font-weight: normal;
            line-height: 18px;
            letter-spacing: 0.15px;
            color: #FFFFFF;
            margin-bottom: 0;
        }
    }
    &__copyright{
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        span{
            @include font-roboto;
            font-weight: normal;
            font-size: 16px;
            line-height: 32px;
            color: #FFFFFF;
        }
    }
    @media (max-width: 1600px) {
        .section-inner{
            padding: 130px 0 0;
        }
        &__block-header{
            font-size: 19.9863px;
            line-height: 27px;
        }
        &__list{
            &-item{
                font-size: 13.3242px;
                line-height: 30px;
            }
        }
        &__social{
            &--hm{
                .section-footer{
                    &__social{
                        &-logo{
                            padding: 5px 7px;
                            width: 80px;
                            img{
                                width: 100%;
                            }
                        }
                        &-text{
                            font-size: 9.99313px;
                            line-height: 15px;
                        }
                    }
                }
            }
    
            &-logo{
                margin-right: 15px;
                width: 30px;
                img{
                    width: 100%;
                }
            }
    
            &-text{
                font-size: 13.3242px;
                line-height: 27px;
            }
        }
        &__copyright{
            display: flex;
            justify-content: space-between;
            padding: 30px 0;
            span{
                font-size: 13.3242px;
                line-height: 27px;
            }
        }
    }

    @media (max-width: 1360px) {
        .section-inner{
            padding: 150px 0 0;
        }
        &__block-header{
            font-size: 16.9898px;
            line-height: 23px;
        }
        &__list{
            margin-top: 30px;
            &-item{
                font-size: 13px;
                line-height: 25px;
            }
        }
        &__social{
            &--hm{
                .section-footer{
                    &__social{
                        &-logo{
                            padding: 4.6px 6px;
                            width: 68px;
                            img{
                                width: 100%;
                            }
                        }
                        &-text{
                            font-size: 10px;
                            line-height: 13px;
                        }
                    }
                }
            }
    
            &-logo{
                margin-right: 12.5px;
                width: 25px;
            }
    
            &-text{
                font-size: 13px;
                line-height: 23px;
            }
        }
        &__copyright{
            span{
                font-size: 13px;
                line-height: 23px;
                &:first-child{
                    max-width: 615px;
                }
            }
        }
    }

    @media (max-width: 960px) {
        .section-inner{
            padding: 50px 0 0;
        }
        &__block-header{
            font-size: 11.9916px;
            line-height: 16px;
        }
        &__list{
            margin-top: 21px;
            &-item{
                font-size: 10px;
                line-height: 20px;
            }
        }
        &__social{
            margin-bottom: 15px;
            &--hm{
                .section-footer{
                    &__social{
                        &-logo{
                            padding: 0px 3.5px 3.5px;
                            width: 48px;
                        }
                        &-text{
                            font-size: 8px;
                            line-height: 9px;
                        }
                    }
                }
            }
    
            &-logo{
                margin-right: 8px;
                width: 18px;
            }
    
            &-text{
                font-size: 10px;
                line-height: 16px;
                word-break: break-all;
            }
        }
        &__copyright{
            span{
                font-size: 10px;
                line-height: 16px;
            }
        }
    }
    @media (max-width: 768px) {
        .section-inner{
            padding: 50px 0 0;
        }
        &__row{
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &__block-header{
            font-size: 11.9916px;
            line-height: 16px;
            margin-top: 15px;
        }
        &__list{
            margin-top: 15px;
            &-item{
                font-size: 10px;
                line-height: 20px;
            }
        }
    }
    @media (max-width: 501px) {
        &__block-header{
            font-size: 11.9916px;
            line-height: 16px;
            margin-top: 0;
        }
        &__block{
            width: 47%;
        }
        &__list{
            margin-top: 21px;
            &-item{
                font-size: 10px;
                line-height: 20px;
                word-break: break-all;
            }
        }
        &__social{
            margin-bottom: 10px;
    
            &-logo{
                margin-right: 8px;
                width: 18px;
            }
    
            &-text{
                font-size: 9px;
                line-height: 16px;
            }
        }
        &__copyright{
            flex-direction: column;
            padding: 15px 0;
            span{
                font-size: 10px;
                line-height: 16px;
                margin-bottom: 10px;
            }
        }
    }
}