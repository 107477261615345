 @import '../helpers.scss';


 
.ontop, #growls-default, #growls-tl, #growls-tr, #growls-bl, #growls-br, #growls-tc, #growls-bc, #growls-cc, #growls-cl, #growls-cr {
  z-index: 50000;
  position: fixed; }
#growls-tc .growl, #growls-bc .growl {
  margin-left: auto;
  margin-right: auto; }

.growl {
  opacity: 0.8;
  filter: alpha(opacity=80);
  position: relative;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  .growl.growl-incoming {
    opacity: 0;
    filter: alpha(opacity=0); }
  .growl.growl-outgoing {
    opacity: 0;
    filter: alpha(opacity=0); }

    
 .growl{
  &-custom{
      width: 100%;
      height: 226px;
      background-color: #fff;
      opacity: 1!important;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  }
  &-message{
      @include font-roboto;
      font-weight: 500;
      font-size: 24px!important;
      line-height: 28px;
      margin-top: 25px;
  }
  &-close{
      font-family: 'verdana', sans-serif!important;
      position: absolute;
      top: 20px;
      right: 15px;
      font-weight: normal!important;
      font-size: 48px!important;
      color: #979797!important;
  }

  @media (max-width: 1360px) {
      &-message{
          font-size: 18px!important;
      }
      &-title{
          width: 50px;
          img{
              width: 100%;
          }
      }
  }
  @media (max-width: 960px) {
      &-custom{
          height: 180px;
      }
      &-message{
          font-size: 18px!important;
      }
      &-title{
          width: 50px;
          img{
              width: 100%;
          }
      }
      &-close{
          font-size: 42px!important;
      }
  }
  @media (max-width: 768px) {
      &-custom{
          height: 160px;
      }
      &-message{
          font-size: 16px!important;
      }
      &-title{
          width: 45px;
      }
      &-close{
          font-size: 36px!important;
      }
  }
  @media (max-width: 680px) {
      &-custom{
          height: 120px;
      }
      &-title{
          width: 35px;
      }
      &-message{
          font-size: 12px!important;
          margin-top: 10px;
      }
      &-close{
          font-size: 16px!important;
      }
  }

}
